import React from "react";
import "./Construction.css";
import { useNavigate } from "react-router-dom";

const Construction = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="Construction-page">
        <div className="background-image">
          <img
            className="bg-img"
            src={`${process.env.PUBLIC_URL}/bg-img.png`}
            alt="bg-img"
          />
        </div>
        <div className="logo-container">
          <div className="logos">
            <img
              className="App-logo"
              src={`${process.env.PUBLIC_URL}/icon-cons.svg`}
              alt="logo"
            />
            <img
              className="App-logo-text"
              src={`${process.env.PUBLIC_URL}/logo_text.svg`}
              alt="logo"
            />
          </div>
          <div className="cons-error-text">
            This Site is under Construction!!
          </div>
        </div>
      </div>
    </>
  );
};

export default Construction;
