import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Construction from "./components/ErrorPages/Construction.js";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Construction />} />
    </Routes>
  );
};

export default App;

// // TEST CODE TO CHECK CONNECTION ESTABLISHMENT
// import React, { useEffect, useState } from "react";
// import { fetchData } from "./components/api-helpers.js/api-helper";

// const App = () => {
//   const [data, setData] = useState(null);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     setError("");
//     const getData = async () => {
//       try {
//         const result = await fetchData();
//         console.log("Data received:", result);
//         setData(result);
//       } catch (err) {
//         console.error("Error fetching data:", err);
//         setError(err.message || "Error receiving data");
//       }
//     };

//     getData();
//   }, []);

//   return (
//     <>
//       <h1>Data from Express Server</h1>
//       {error && <p className="error">{error}</p>}
//       <pre>{data ? JSON.stringify(data, null, 2) : "Loading..."}</pre>
//     </>
//   );
// };

// export default App;
